body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
    font-family: 'Plus Jakarta sans';

 
}
@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap');





@tailwind base;
@tailwind components;
@tailwind utilities;



html {
  scroll-behavior: smooth;
}

.carousel-container {
  @apply overflow-hidden;
  width: 100%;
}

.carousel {
  @apply flex;
  scroll-behavior: smooth;
}

.carousel-item {
  @apply flex-none;
}

.carousel-item img {
  @apply w-full h-full object-cover;
}

.carousel-image-container {
  max-height: 60vh; /* Set a max height for the container */
  display: flex;
  justify-content: center; /* Center horizontally */
  border-radius: 12px; /* Apply 12px border radius */
  /* align-items: center; Center vertically */
}

.carousel-image {
  max-height: 100%; /* Limit image height to container height */
  max-width: 100%; /* Limit image width to container width */
  object-fit: contain; /* Ensure the whole image shows */
  border-radius: 12px; /* Apply 12px border radius */
}
.custom-dot-list-style {
  margin-top: 100px; /* Adjust for spacing */
  display: flex;
  justify-content: center;
  align-items: center;
}

.custom-dot-list-style li button {
  background-color: #C9C4C4; /* Default color */
  width: 13px;
  height: 13px;
  margin-top: 40px; /* Adjust for spacing */
  margin: 0 3px;
  border-radius: 50%;
  opacity: 0.6;
  transition: background-color 0.3s ease, opacity 0.3s ease;
}

.custom-dot-list-style li.react-multi-carousel-dot--active button {
  background-color: #027DCB !important; /* Active dot color */
  opacity: 1 !important;
  border-radius: 50%; /* Ensures the active dot border is also rounded */
}
ul.react-multi-carousel-dot-list {
  position: absolute;
  bottom: 30px;
  display: flex;
  left: 0;
  right: 0;
  justify-content: center;
  margin: auto;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
}

/* Hide scrollbar for modern browsers */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.no-scrollbar::-webkit-scrollbar {
  display: none; /* Chrome, Safari, and Opera */
}

/* For most modern browsers */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* For Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}


.scrollbar-none::-webkit-scrollbar {
  display: none;
}

.scrollbar-none {
  -ms-overflow-style: none; /* For Internet Explorer 10+ */
  scrollbar-width: none; /* For Firefox */
}


.mySwiper{
  width: 100%;
  height: 100%;
  /* background-color: aqua; */
}
.swiper-button-next{
  color: red;
  /* background: green; */
  position: absolute;
  z-index: 10;
  right: 20;
  top: 50%;
}
.swiper-button-prev{
  color: red;
}